@import "../../assets/styles/variables.scss";

#side-bar {
  background-color: #000000;
  color: $white;

  padding: 40px 0;
  width: 20%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;

  img {
    padding: 20px 0;
  }

  ul {
    li.selected {
      background: var(--Dark-colors-dark-03, #202020);
    }
  }
}
