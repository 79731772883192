.tag-teams {
  list-style-type: none;
  max-height: 100px;
  overflow: scroll;
  padding: 10px;
  border-radius: 25px;
  border: 2px solid #130a39;
}

table.table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        text-align: center;
      }
    }
  }
}

button.external-login-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 4px;

  border-radius:16px;
  p {
    margin-bottom: 0;
  }
  .icon--external-link {
    height: 16px;
    width: 16px;
    background-color: #fff;
  }
}

.icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  &--external-link {
    mask: url("../../../assets/images/external-link.svg") no-repeat center;
  }
}