.fill {
  min-height: 100%;
  height: 100%;
}

button,
.btn,
.input-group-append {
  border-radius: var(--4px, 4px);
  background: var(--Core-accent, #6464ff);
  border: none;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    background: var(--Primitives-royal-300, #8787ff) !important;
    border: none !important;
  }
  &:focus {
    border: 3px solid var(--Primitives-royal-200, #aaf) !important;
    background: var(--Core-accent, #6464ff) !important;
    box-shadow: none !important;
  }
}

a {
    color: #6464FF;
}

.blanket {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: black;
  opacity: 0.4;
}
